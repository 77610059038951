@tailwind base;
@tailwind components;
@tailwind utilities;

/* @layer base {
  h1 {
    @apply block text-[2em] font-bold;
  }
  h2 {
    @apply text-[1em] block font-bold;
  }
  h3 {
    @apply text-[12px] block font-bold;
  }
} */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  
}