.loader {
    width: 50px;
    height: 50px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: auto;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.typing-effect {
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid white; /* Simulated cursor */
    width: 0;
    animation: typing 2s steps(15, end) forwards, blink 0.7s infinite 3s, remove-cursor 0.5s linear 3s forwards;
}

.typing-effect-delayed {
    
    white-space: nowrap;
    overflow: hidden;
    border-right: 2px solid white; /* Simulated cursor */
    display: inline-block;
    width: 0;
    opacity: 0; /* Initially hidden */
    animation: fade-in 0.5s ease-in 2.5s forwards, typing 2s steps(15, end) 3s forwards, blink 1s infinite 6s;
}

@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

@keyframes blink {
    50% {
        border-color: transparent;
    }
}

@keyframes remove-cursor {
    to {
        border-right: transparent;
    }
}

@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

